<template>
<v-card>
  <v-card-title>
    <span class="headline">{{ action | title }} user</span>
  </v-card-title>
  <v-card-text>
    <v-container>
      <v-row dense>
        <v-col cols="12" sm="5">
          <v-text-field v-model="name" label="Name" autocomplete="off"></v-text-field>
        </v-col>
        <v-col cols="5" sm="3">
          <v-select v-model="role" :items="roleOptions" label="Role"></v-select>
        </v-col>
        <v-col cols="4" sm="3">
          <v-select v-model="status" :items="statusOptions" label="Status"></v-select>
        </v-col>
        <v-col v-if="status == 'employee'" cols="3" sm="1">
          <v-select v-model="days" :items="dayOptions" label="Days"></v-select>
        </v-col>
      </v-row>
      <v-row dense>
        <template v-if="status === 'freelance' || userInFinance">
          <v-col cols="6" sm="2">
            <v-text-field v-model="salary" label="Paid (/yr)"
                          prefix="£" type="number">
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="2">
            <v-text-field v-model="paid" label="Paid (/hr)"
                          prefix="£" type="number" @input="paidChange">
            </v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </template>
        <v-col cols="6" sm="2">
          <v-text-field v-model="passcode" label="Passcode" type="number">
          </v-text-field>
        </v-col>
        <v-col cols="6" sm="2">
          <v-checkbox v-model="admin" label="Admin"></v-checkbox>
        </v-col>
        <v-col cols="12" sm="3">
          <v-checkbox v-model="allow_role_change" label="Role change"></v-checkbox>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field v-model="email" label="Email" type="text"></v-text-field>
        </v-col>
        <v-col v-if="admin && userIsSuper">
          <v-checkbox v-model="viewFinance" label="View Finance"></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="status == 'freelance'" dense>
        <v-col v-if="additionalRoles.length > 0">
          Additional paid roles:
          <v-chip v-for="role in additionalRoles" :key="role.name"
                  close outlined
                  class="mr-1"
                  @click:close="deletePaidRole(role.id)">
            {{ role.name }} @ £{{ role.hourly_rate }}/hr
          </v-chip>
        </v-col>
      </v-row>
    </v-container>
  </v-card-text>
  <v-card-actions>
    <template v-if="action === 'edit'">
      <v-btn v-if="active" text color="error" @click="activate(false)">Deactivate</v-btn>
      <v-btn v-else text color="green" @click="activate(true)">Activate</v-btn>
    </template>
    <add-paid-role v-if="status === 'freelance'" :user-id="user_id"
                         @add="fetchPaidRoles">
    </add-paid-role>
    <v-spacer></v-spacer>
    <v-btn text @click="$emit('close')">Cancel</v-btn>
    <v-btn text :disabled="!valid" @click="save">Save</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { hash, hourlyToSalary, salaryToHourly } from '../../util'
import * as api from '../../api'
import { AdminLevel, userOptions } from '../../data'
import AddPaidRole from './AddPaidRole'

export default {
  name: 'UserDialog',
  components: { AddPaidRole },
  data() {
    return {
      action: 'add',
      user_id: null,
      name: '',
      role: '',
      status: '',
      days: 5,
      paid: null,
      admin: 0,
      passcode: '',
      allow_role_change: false,
      email: '',
      active: null,
      viewFinance: false,
      additionalRoles: [],
      roleOptions: userOptions.role.map(r => ({ value: r, text: this.makeFullTitle(r) })),
      statusOptions: userOptions.status.map(s => ({ value: s, text: this.makeTitle(s) })),
      dayOptions: [1, 2, 3, 4, 5],
      cache: {
        paid: null,
        role: null,
        status: null,
        days: null
      },
      salary: null
    }
  },
  computed: {
    ...mapState(['user', 'users']),
    ...mapGetters(['userIsSuper', 'userInFinance']),
    valid() {
      let v = (
        this.name && this.role && this.status && this.paid
      )
      if (this.action === 'add') {
        v = v && this.passcode
      }
      return v
    }
  },
  watch: {
    salary() {
      this.paid = salaryToHourly(this.salary)
    }
  },
  methods: {
    setID(id) {
      if (id) {
        this.action = 'edit'
        this.user_id = id
        const user = this.$store.getters.userForID(id)
        Object.assign(this.$data, user)
        this.viewFinance = this.admin >= AdminLevel.FINANCE
        for (const k in this.cache) {
          this.cache[k] = this[k]
        }
        this.fetchPaidRoles()
      } else {
        this.action = 'add'
        this.name = this.role = this.status = this.email = ''
        this.paid = null
        this.admin = AdminLevel.NONE
        this.allow_role_change = false
        this.viewFinance = false
      }
      this.passcode = null
      this.salary = hourlyToSalary(this.paid || 0)
    },
    async save() {
      const { user_id, name, role, status, paid, allow_role_change, email } = this
      const days = status === 'employee' ? this.days : null
      let admin = this.admin ? AdminLevel.ADMIN : AdminLevel.NONE

      if (admin && this.viewFinance) {
        admin = AdminLevel.FINANCE
      }

      if (this.action === 'edit') {
        await api.put('/user/details', {
          user_id,
          name: name.trim(),
          status,
          admin,
          allow_role_change,
          email
        })

        for (const target in ['paid', 'role']) {
          if (this.cache[target] !== this[target]) {
            const value = this[target]
            const endpoint = `/user/${target}`
            await api.put(endpoint, {
              user_id,
              [target]: value
            })
            this.cache[target] = value
          }
        }

        if (this.cache.status !== this.status || this.cache.days !== this.days) {
          await api.put('/user/status', {
            user_id, status, days
          })
          this.cache.status = status
          this.cache.days = days
        }

        if (this.passcode) {
          const passcode_hash = hash(this.passcode)
          await api.put('/user/passcode', {
            user_id,
            passcode_hash
          })
        }
      } else { // add
        const passcode_hash = hash(this.passcode)
        await api.post('/user', {
          name,
          role,
          status,
          days,
          admin,
          paid,
          allow_role_change,
          passcode_hash,
          email
        })
      }

      return this.finish()
    },
    async activate(value) {
      const { user_id } = this
      await api.put('/user/activate', { user_id, value })
      return this.finish()
    },
    finish() {
      this.$emit('close')
      return this.$store.dispatch('getUsers', true)
    },
    paidChange() {
      this.salary = hourlyToSalary(this.paid)
    },
    async fetchPaidRoles() {
      const { user_id } = this
      this.additionalRoles = await api.get('/paid-roles', { user_id })
      if (user_id === this.user.id) {
        this.user.paid_roles = this.additionalRoles
      }
    },
    async deletePaidRole(id) {
      await api.del('/paid-role', { role_id: id })
      await this.fetchPaidRoles()
      return this.$store.dispatch('getUsers', true)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
